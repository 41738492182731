import axios from "axios";
import api_ from "@/store/common/api.js";
import code_ from "@/store/common/code.js";
import system_ from "@/store/common/system.js";
import router from "@/router";
import { ElLoading, ElNotification } from "element-plus";
import pcj from "@/assets/js/aes_encrypt.js";

const request_lna = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000,
});

// post请求头
request_lna.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

// 设置cross跨域 并设置访问权限 允许跨域携带cookie信息
if (api_.state.Pub.server_method === 1) {
  request_lna.defaults.crossDomain = true;
  request_lna.defaults.withCredentials = true;
}

let loadingStatus = [];

request_lna.interceptors.request.use((config) => {
  loadingStatus = ElLoading.service({
    lock: true,
    text: "努力请求中...",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.8)",
  });

  // 提取data信息
  const request_data = config.data;

  // 判断data内容是否存在
  if (
    typeof request_data != "undefined" &&
    Object.prototype.toString.call(request_data) != "[object FormData]" &&
    typeof request_data.encryption === "undefined"
  ) {
    // 对data数据进行aes加密操作
    const aes_data = pcj.aesEncrypt(request_data);

    if (aes_data === false) {
      return Promise.reject({
        Code: code_.state.fail,
        Message: "AES加密失败",
      });
    } else {
      config.data = {
        encryption: aes_data,
      };
    }
  }

  return config;
});

request_lna.interceptors.response.use(
  (response) => {
    // 取消加载层
    loadingStatus.close();

    // 根据接口返回告知登录信息不存在，重新请求登录
    if (
      typeof response.data.Code != "undefined" &&
      response.data.Code === code_.state.login_reload &&
      typeof router.currentRoute._value.meta.module != "undefined" &&
      router.currentRoute._value.meta.module != "Login"
    ) {
      // 存放当前地址
      if (
        typeof router.currentRoute._value.meta.module === "undefined" ||
        router.currentRoute._value.meta.module != "Login"
      ) {
        api_.commit(
          "setCurrentPath",
          JSON.stringify({
            path: router.currentRoute.value.path,
            query: router.currentRoute.value.query,
          })
        );
      }

      // 跳转到默认登录页面
      router.push({
        name: system_.state.login_path,
      });
    }

    // 判断返回信息中是否存在加密的数据
    if (typeof response.data.Data === "undefined") {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(pcj.aesDecrypt(response));
    }
  },
  (error) => {
    // 取消加载层
    loadingStatus.close();

    // 请求错误，提示错误信息
    ElNotification.error({
      title: "温馨提示",
      message:
        typeof error === "object" && error.Message
          ? error.Message
          : "网络连接中断，请稍后重试",
    });

    // 请求错误，返回错误信息
    return Promise.reject(
      typeof error === "object"
        ? error
        : {
            Code: code_.state.FAIL,
            Message: "网络连接中断，请稍后重试",
          }
    );
  }
);

export default request_lna;
