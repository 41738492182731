import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		success: 200, //请求成功并政策返回
		fail: 202, //请求失败
		fail_reload: 203, //请求超时
		login_reload: 204 //登录信息过期
	}
})
