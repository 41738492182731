import {
	createStore
} from 'vuex'
import Menu from '@/assets/json/pub/menu/index.json'

export default createStore({
	state: {
		Menu: Menu
	},
	mutations: {},
	modules: {}
})
