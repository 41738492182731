import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		applic_name: "", //系统名称
		applic_sname: "", //系统名称简写
		applic_logo: "", //系统logo
		applic_bg: "", //系统背景图
		company_name: "", //版权所属公司名称
		company_tel: "", //板块所属公司联系电话
		system_record_no: "", //备案号
		system_version: "", //系统版本
		technical_support: "", //技术提供方
		captcha_appid: "", //腾讯防水墙Appid
		frame_path: "frame", //系统首页
		login_path: "sign_account", //默认登录地址
		refresh_change_trains_path: "menu_change_trains", //刷新页面需要跳转过渡的中间页面
		refresh_to_path: "", //刷新页面从过渡需要前往的页面
		login_user_name: "", //登录人员名称
		login_user_tel: "", //登录人员手机号
	},
	mutations: {
		setSysInfo(state, data) {
			if (data.applic_name) {
				state.applic_name = data.applic_name
			}
			if (data.applic_sname) {
				state.applic_sname = data.applic_sname
			}
			if (data.applic_logo) {
				state.applic_logo = data.applic_logo
			}
			if (data.applic_bg) {
				state.applic_bg = data.applic_bg
			}
			if (data.company_name) {
				state.company_name = data.company_name
			}
			if (data.company_tel) {
				state.company_tel = data.company_tel
			}
			if (data.system_record_no) {
				state.system_record_no = data.system_record_no
			}
			if (data.system_version) {
				state.system_version = data.system_version
			}
			if (data.technical_support) {
				state.technical_support = data.technical_support
			}
			if (data.captcha_appid) {
				state.captcha_appid = data.captcha_appid
			}
			if (data.login_user_name) {
				state.login_user_name = data.login_user_name
			}
			if (data.login_user_tel) {
				state.login_user_tel = data.login_user_tel
			}
			if (data.refresh_to_path) {
				state.refresh_to_path = data.refresh_to_path
			}
		}
	},
	actions: {},
	modules: {}
})
