import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import {
	ElNotification
} from 'element-plus'
import {
	getCurrentInstance
} from 'vue'
import Frame from './frame/frame.js'
import Camera from './camera/camera.js'
import Face from './face/face.js'
import Inspection from './inspection/inspection.js'
let baseRoutes = []
let routes = baseRoutes.concat(Frame, Camera, Face, Inspection)

const router = createRouter({
	history: createWebHashHistory(),
	routes
})
	
router.beforeEach((to, from, next) => {
	if (JSON.stringify(to.meta) === '{}') {
		ElNotification({
			title: '温馨提示',
			message: '404！当前页面不存在',
			type: 'error'
		})
		setTimeout(() => {
			window.location.href = '/'
		}, 1000)
	} else {
		// 设置当前页面标题
		if (to.meta.title) {
			// document.title =JSON.parse(localStorage.getItem("systemInfo")).applic_sname + "-" +to.meta.title;
		}
		next()
	}
})

export default router
