/* 人脸管理 */
export default [
  {
    meta: {
      title: "售楼部",
      page_main_title: "人脸管理-售楼部",
      loadContainer: true,
      module: "Face",
    },
    path: "/sales_dep",
    name: "sales_dep",
    component: () => import("@/views/face/sales_dep.vue"), // 售楼部
  },
  {
    meta: {
      title: "人脸集合",
      page_main_title: "人脸管理-人脸集合",
      loadContainer: true,
      module: "Face",
    },
    path: "/face_set",
    name: "face_set",
    component: () => import("@/views/face/face_set.vue"), // 人脸集合
  },
  {
    meta: {
      title: "人脸搜索",
      page_main_title: "人脸管理-人脸搜索",
      loadContainer: true,
      module: "Face",
    },
    path: "/face_search",
    name: "face_search",
    component: () => import("@/views/face/face_search.vue"), // 人脸搜索
  },
  {
    meta: {
      title: "参数配置",
      page_main_title: "人脸管理-参数配置",
      loadContainer: true,
      module: "Face",
    },
    path: "/face_rate_set",
    name: "face_rate_set",
    component: () => import("@/views/face/face_rate_set.vue"), // 参数配置
  },
];
