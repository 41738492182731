import {
	createStore
} from 'vuex'
import pubApi from '@/assets/json/pub/api/index.json'
import frameApi from '@/assets/json/frame/api/index.json'
import caremaApi from '@/assets/json/carema/api/index.json'
import faceApi from '@/assets/json/face/api/index.json'
import inspectionApi from '@/assets/json/inspection/api/index.json'
export default createStore({
	state: {
		Pub: pubApi,
		CurrentPath: localStorage.getItem('current_path') ? localStorage.getItem('current_path') : '',
		Frame: frameApi,
		Carema: caremaApi,
		Face: faceApi,
		Inspection: inspectionApi
	},
	mutations: {
		setCurrentPath(state, data) {
			if (data) {
				localStorage.setItem('current_path', data)
			} else {
				localStorage.removeItem('current_path')
			}

			state.CurrentPath = data
		}
	},
	actions: {},
	modules: {}
})
