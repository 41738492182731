import api_ from '@/store/common/api.js'
import code_ from '@/store/common/code.js'
import menu_ from '@/store/common/menu'
import router from '@/router'
import request_ from "@/utils/request"
import request_nl_ from "@/utils/request_nl"
import {
	ElNotification
} from 'element-plus'

const pf = {
	verify_permiss: (role, roles) => {
		// 验证用户权限信息
		return roles.indexOf(role) > -1 ? true : false
	},
	verify_tableColumns: (tableColumnsList, menuName) => {
		// 判断table列是否有权限
		const tableColumns = []

		tableColumnsList.forEach((v, i) => {
			// 判断是否显示该列
			if (v.show === true) {
				// 赋值存入
				tableColumns.push(JSON.parse(JSON.stringify(v)))

				if (v.type === 'button') {
					// 判断行按钮是否拥有操作权限
					v.btns.forEach((iv, ii) => {
						if (iv.role && !pf.verify_permiss(api_['state'][router.currentRoute
								._value.meta.module
							][
								menuName
							][iv.role]['token'], menu_.state.roleNode)) {
							// 无权限删除该按钮
							tableColumns[i].btns.splice(v.ii, 1)
						}
					})
				} else if (v.type === 'button_icon') {
					// 判断行按钮图标是否拥有操作权限
					v.btns_icon.forEach((iv, ii) => {
						if (iv.role && !pf.verify_permiss(api_['state'][router.currentRoute
								._value.meta.module
							][
								menuName
							][iv.role]['token'], menu_.state.roleNode)) {
							// 无权限删除该按钮图标
							tableColumns[i].btns_icon.splice(v.ii, 1)
						}
					})

				} else if (v.type && v.role) {
					// 判断行编辑是否拥有操作权限
					if (!pf.verify_permiss(api_['state'][router.currentRoute._value.meta.module][
							menuName
						][v.role]['token'], menu_.state.roleNode)) {
						tableColumns[i].disabled = true
					}
				}
			}
		})

		return tableColumns
	},
	sortByKey: (list, key) => {
		// 对象数组排序
		return list.sort(function(a, b) {
			var x = a[key];
			var y = b[key];

			return ((x > y) ? -1 : (x > y) ? 1 : 0)
		})
	},
	reverseByKey: (list, key) => {
		// 对象数组排序
		return list.sort(function(a, b) {
			var x = a[key];
			var y = b[key];

			return ((y > x) ? -1 : (y > x) ? 1 : 0)
		})
	},
	isMobile() {
		// 判断是否为移动端
		let flag = navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		)
		return flag
	},
	isWeiXin() {
		// 判断是否为微信
		let ua = window.navigator.userAgent.toLowerCase()
		if (ua.match(/MicroMessenger/i) == 'micromessenger') {
			return true
		} else {
			return false
		}
	},
	getQueryString(name) {
		//企业微信授权获取code问题解决办法
		const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
		const result = window.location.search.substring(1).match(reg)
		if (result != null) {
			return decodeURIComponent(result[2])
		}
		return null
	},
	getSelectCommonBigarea(update_list) {
		// select框获取公共区域列表
		update_list.is_load = true
		request_nl_.post(api_.state.Frame.BaseInfo.common_bigarea.url)
			.then((respon) => {
				if (respon.Code === code_.state.success) {
					update_list.options = respon.Data
				} else {
					ElNotification.warning({
						title: '温馨提示',
						message: respon.Message
					})

					update_list.options = []
				}

				update_list.is_load = false
			}).catch(error => {
				update_list.is_load = false
			})
	},
	getSelectCommonGroup(update_list, bg_id = 0) {
		// select框获取公共设备分组列表
		update_list.is_load = true
		request_nl_.post(api_.state.Frame.BaseInfo.common_group.url, {
				bg_id: bg_id
			})
			.then((respon) => {
				if (respon.Code === code_.state.success) {
					update_list.options = respon.Data
				} else {
					ElNotification.warning({
						title: '温馨提示',
						message: respon.Message
					})

					update_list.options = []
				}

				update_list.is_load = false
			}).catch(error => {
				update_list.is_load = false
			})
	}
}

export default pf
