/* 系统主模块 */
export default [
  {
    meta: {
      title: "系统首页",
      page_main_title: "系统首页",
      loadContainer: true,
      module: "Frame",
    },
    path: "/",
    name: "frame",
    component: () => import("@/views/frame/Index.vue"), // 系统首页
  },
  {
    meta: {
      title: "菜单中转页",
      page_main_title: "菜单中转页",
      loadContainer: false,
      module: "Frame",
    },
    path: "/menu_change_trains",
    name: "menu_change_trains",
    component: () => import("@/views/frame/MenuChangeTrains.vue"), // 菜单中转页
  },
  {
    meta: {
      title: "账号登录",
      module: "Login",
    },
    path: "/sign_account",
    name: "sign_account",
    component: () => import("@/views/frame/AccountLogin.vue"), // 账号登录
  },
  {
    meta: {
      title: "二维码登录",
    },
    path: "/Init",
    name: "Init",
    component: () => import("@/views/frame/Init.vue"), // 二维码登录跳转
  },
  {
    meta: {
      title: "一键登录",
    },
    path: "/Oauth",
    name: "Oauth",
    component: () => import("@/views/frame/Oauth.vue"), // 企业微信一键登录
  },
];
