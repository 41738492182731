<template>
  <EstContainer
    v-if="loadSysBaseInfo.isLoad && $route.meta.loadContainer === true"
  ></EstContainer>
  <router-view v-else-if="loadSysBaseInfo.isLoad" />
</template>

<script setup>
import "@/assets/css/main.less";
import { getCurrentInstance, onMounted, reactive } from "vue";
import EstContainer from "@/components/common/EstContainer.vue";

// 访问 globalProperties
const ctx = getCurrentInstance().appContext.config.globalProperties;

// 加载系统基础信息标识
const loadSysBaseInfo = reactive({
  isLoad: false,
});

onMounted(() => {
  // 获取系统基础配置信息
  ctx.$request_nl_
    .post(ctx.$api_.state.Frame.BaseInfo.sys_base_info.url)
    .then((respon) => {
      if (respon.Code === ctx.$code_.state.success) {
        ctx.$system_.commit("setSysInfo", respon.Data);
        loadSysBaseInfo.isLoad = true;
        // 本地存储配置信息
        localStorage.setItem('systemInfo', JSON.stringify(respon.Data));
        // 设置网页图标
        let link = document.querySelector("link[rel*='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = respon.Data.applic_ico
        document.getElementsByTagName("head")[0].appendChild(link);
      } else {
        ctx.$notify({
          title: "温馨提示",
          message: respon.Message,
          type: "warning",
        });
      }
    });
});
</script>
