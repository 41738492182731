/* 设备管理 */
export default [
  {
    meta: {
      title: "列表",
      page_main_title: "设备管理-列表",
      loadContainer: true,
      module: "Camera",
    },
    path: "/camera",
    name: "camera",
    component: () => import("@/views/camera/Index.vue"), // 系统首页
  },
  {
    meta: {
      title: "请款统计",
      page_main_title: "设备管理-请款统计",
      loadContainer: true,
      module: "Camera",
    },
    path: "/payment",
    name: "payment",
    component: () => import("@/views/camera/payment.vue"), // 请款统计
  },
];
