/* 巡检 */
export default [{
	meta: {
		title: '日常巡检',
		page_main_title: '云眸巡检-日常巡检',
		loadContainer: true,
		module: 'Inspection'
	},
	path: '/inspection_daily',
	name: 'inspection_daily',
	component: () => import('@/views/inspection/inspection_daily.vue') // 日常巡检
}, {
	meta: {
		title: '巡检管理',
		page_main_title: '云眸巡检-巡检管理',
		loadContainer: true,
		module: 'Inspection'
	},
	path: '/inspection_mgr',
	name: 'inspection_mgr',
	component: () => import('@/views/inspection/inspection_mgr.vue') //巡检管理
}]