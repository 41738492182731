<template>
	<div id="EstContainer">
		<el-container class="main">
			<el-header class="header">
				<div class="top-logo">
					<router-link :to="{name:'frame'}">
						<img class="logo" :src="$system_.state.applic_logo" alt="logo">
					</router-link>
				</div>
				<el-row class="top-right">
					<el-col :span="15" class="user-info">
						<div class="user-name">{{userInfo.user_name}}</div>
					</el-col>
					<el-col :span="9" class="user-exit">
						<el-button type="danger" circle title="退出登录" @click="LoginOut">
							<el-icon>
								<SwitchButton />
							</el-icon>
						</el-button>
					</el-col>
				</el-row>
			</el-header>
			<el-container>
				<el-aside class="left-aside">
					<el-menu :default-active="$route.path" :router="true">
						<el-sub-menu :index="v.index" v-for="(v,i) in leftMenu.list">
							<template #title>
								<component :is="v.icon" class="icon"></component>
								<span>{{v.name}}</span>
							</template>
							<el-menu-item :route="cv.path" :index="cv.path" v-for="(cv,ci) in v.child">{{cv.name}}
							</el-menu-item>
						</el-sub-menu>
					</el-menu>
				</el-aside>
				<el-container>
					<el-main>
						<div class="main-title">
							{{$route.meta.page_main_title}}
							<el-button class="main-title-refresh" type="primary" icon="Refresh" link
								@click="RefreshPage">刷新</el-button>
						</div>
						<el-divider border-style="double" />
						<div class='main-body'>
							<router-view v-slot="{ Component }" v-if="userInfo.user_id > 0">
								<transition name="fade-transform" appear>
									<component :is="Component" />
								</transition>
							</router-view>
						</div>
					</el-main>
					<el-footer class="footer">
						<span>Copyright {{$system_.state.company_name}} {{$system_.state.company_tel}} |</span>
						<span>{{ $system_.state.applic_name }} | 版本号 {{ $system_.state.system_version }} | </span>
						<span>
							备案号：<a target="_blank"
								href="https://beian.miit.gov.cn">{{ $system_.state.system_record_no }}</a>
						</span>
					</el-footer>
				</el-container>
			</el-container>
		</el-container>
	</div>
</template>
<script setup>
	import {
		getCurrentInstance,
		onMounted,
		reactive
	} from 'vue'
	import {
		useRoute
	} from "vue-router"

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	// 获取左侧菜单
	const leftMenu = reactive({
		list: []
	})

	// 用户信息
	const userInfo = reactive({
		user_id: '',
		user_name: '',
		user_tel: ''
	})

	// 刷新当前页面
	const RefreshPage = () => {
		// 存放当前路径
		ctx.$system_.commit('setSysInfo', {
			refresh_to_path: ctx.$route.name
		})

		ctx.$router.push({
			name: ctx.$system_.state.refresh_change_trains_path
		})
	}

	// 退出登录
	const LoginOut = () => {
		ctx.$request_.post(ctx.$api_.state.Frame.Login.login_out.url)
			.then((respon) => {
				if (respon.Code === 200) {
					// 清除水印
					ctx.$watermark_.set('')
					// 跳转到默认登录页面
					ctx.$router.push({
						name: ctx.$system_.state.login_path
					})

					ctx.$notify({
						title: '温馨提示',
						message: respon.Message,
						type: 'success'
					})
				} else {
					ctx.$notify({
						title: '温馨提示',
						message: respon.Message,
						type: 'warning'
					})
				}
			})
	}

	onMounted(() => {
		// 获取用户信息
		ctx.$request_nl_.post(ctx.$api_.state.Frame.BaseInfo.user_info.url)
			.then((respon) => {
				if (respon.Code === 200) {
					userInfo.user_id = respon.Data.user_id
					userInfo.user_name = respon.Data.user_name
					userInfo.user_tel = respon.Data.user_tel

					// 存放登录人员信息
					ctx.$system_.commit('setSysInfo', {
						login_user_name: userInfo.user_name,
						login_user_tel: userInfo.user_tel
					})

					// 设置水印
					ctx.$watermark_.set(userInfo.user_name + userInfo.user_tel.substr(-4))

					// 设置左侧菜单
					leftMenu.list = ctx.$menu_.state.Menu
				} else {
					ctx.$notify({
						title: '温馨提示',
						message: respon.Message,
						type: 'warning'
					})
				}
			})
	})
</script>
<style lang="less">
	#EstContainer {
		height: 100vh;

		.fade-transform-enter-active {
			transition: all 0.5s;
		}

		.fade-transform-enter-from {
			opacity: 0;
			transform: translateX(50px);
		}

		.main {
			height: 100%;

			.header {
				padding: 0;
				height: 3vw;
				background: var(--el-color-white);
				box-shadow: 10px 10px 9px var(--el-color-info);

				.top-logo {
					float: left;
					width: 10%;

					.logo {
						width: 8.5vw;
						padding-top: 0.2vw;
						padding-left: 0.5vw;
					}
				}

				.top-right {
					float: right;
					width: 10%;
					padding-right: 1.7vw;

					.user-info {
						text-align: right;
						padding-top: 1vw;

						.user-name {
							font-size: 0.8vw;
							color: var(--el-color-info);
						}
					}

					.user-exit {
						padding-left: 1vw;
						padding-top: 0.6vw;
					}
				}
			}

			.left-aside {
				width: 10vw;
				text-align: center;
				box-shadow: 10px 10px 9px var(--el-color-info);

				.el-menu {
					height: 100%;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					z-index: 100;

					.icon {
						width: 0.82vw;
						height: 0.82vw;
						margin-right: 0.3vw;
					}
				}
			}

			.el-main {
				height: 0;
				background-color: var(--el-menu-bg-color);
				margin: 0.7vw;
				padding: 0;
				border-radius: 5px;
				box-shadow: 10px 10px 9px var(--el-color-info);
				overflow: -moz-scrollbars-none;

				.main-title {
					color: var(--el-text-color-primary);
					padding: 1vh 0 0 1vw;

					.main-title-refresh {
						float: right;
						margin-right: 1vw;
					}
				}

				.el-divider--horizontal {
					margin: 1.5vh 0;
				}

				.main-body {
					padding: 0 1vw 1vh 1vw;
				}
			}

			.el-main::-webkit-scrollbar {
				width: 0 !important
			}

			.footer {
				background-color: var(--el-menu-bg-color);
				color: var(--el-color-info);
				font-size: 12px;
				height: 4vh;
				line-height: 4vh;

				a {
					text-decoration: none;
					color: var(--el-color-info);
				}
			}
		}
	}
</style>
